const csvToJSON = (csv: any) => {
  const lines = csv.split('\n');
  const result: any[] = [];
  const headers = lines[0].split(';');

  for (let i = 1; i < lines.length; i++) {
    const obj: any = {};

    if (lines[i] == undefined || lines[i].trim() == '') {
      continue;
    }

    const words = lines[i].split(';');
    for (let j = 0; j < words.length; j++) {
      obj[headers[j].trim()] = words[j].replace(/(?:\r\n|\r|\n)/g, '');
    }

    result.push(obj);
  }
  return result;
};

export function parse(file: File) {
  // Always return a Promise
  return new Promise<any[]>((resolve, reject) => {
    let content = '';
    const reader = new FileReader();
    // Wait till complete
    reader.onloadend = function (e: any) {
      content = e.target.result;
      const result: any[] = csvToJSON(content);
      resolve(result);
    };
    // Make sure to handle error states
    reader.onerror = function (e: any) {
      reject(e);
    };
    reader.readAsText(file);
  });
}

export function readToString(file: File) {
  // Always return a Promise
  return new Promise<any[]>((resolve, reject) => {
    let content = '';
    const reader = new FileReader();
    // Wait till complete
    reader.onloadend = function (e: any) {
      content = e.target.result;
      resolve([content]);
    };
    // Make sure to handle error states
    reader.onerror = function (e: any) {
      reject(e);
    };
    reader.readAsText(file);
  });
}
