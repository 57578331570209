import { CheckCircle } from '@mui/icons-material';
import { Box, Button, LinearProgress, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectsContext } from '../../context/ProjectsContext';
import { SequencesContext } from '../../context/SequencesContext';

const StageProgress = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentStage } = useContext(ProjectsContext);
  const { sequence, getNextIncompleteSequence } = useContext(SequencesContext);
  if (!currentStage) return null;

  const currentProgress = currentStage.progress.completed / currentStage.progress.total;

  const getProgressPercentage = () => {
    if (!currentStage) return 0;
    return currentProgress * 100;
  };

  const getContinueButtonText = () => {
    if (currentProgress === 1) return 'completed';
    if (currentProgress === 0) return 'start';
    return 'continue';
  };

  const continueAnnotation = () => {
    //const nextSequence = getNextIncompleteSequence();
    let nextSequence;
    if (sequence) {
      nextSequence = sequence;
    } else {
      nextSequence = getNextIncompleteSequence();
    }

    if (nextSequence) {
      if (nextSequence.completed) {
        navigate(
          `/projects/${id}/stages/${currentStage.id}/sequences/${nextSequence.id}/images/${nextSequence.main_image_id}`
        );
      } else {
        navigate(`stages/${currentStage.id}/sequences/${nextSequence.id}/images`);
      }
    }
  };

  return (
    <Box p={2} pl={3}>
      <Typography>Current Progress</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={getProgressPercentage()} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(getProgressPercentage())}%`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', mx: 2 }}>
          {currentProgress === 1 ? (
            <Tooltip title="Annotation Completed!">
              <CheckCircle color="success" />
            </Tooltip>
          ) : (
            <Button color="primary" variant="contained" sx={{ ml: 'auto' }} onClick={continueAnnotation}>
              Continue
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StageProgress;
