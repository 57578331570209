import axios from 'axios';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';

export const parentTemplate = (patches: any) => {
  return {
    lockOnCompletion: true,
    completed: false,
    description: 'Categorize this case based on all images in the sequence',
    steps: patches.map((patch: any, index: number) => ({
      id: patch.id,
      type: 'questionnaire',
      completed: false,
      patch,
      template: [
        {
          id: 0,
          label: 'Please chooose one of the following options for the displayed patch:',
          type: 'radio',
          condition: null,
          options: [
            {
              id: 'lines_reticular',
              label: 'Lines reticular',
              terminal: false,
            },
            {
              id: 'lines_branched',
              label: 'Lines branched',
              terminal: false,
            },
            {
              id: 'lines_parallel',
              label: 'Lines parallel',
              terminal: false,
            },
            {
              id: 'lines_radial',
              label: 'Lines radial',
              terminal: false,
            },
            {
              id: 'lines_curved',
              label: 'Lines curved',
              terminal: false,
            },
            {
              id: 'lines_angulated',
              label: 'Lines angulated',
              terminal: false,
            },
            {
              id: 'pseudopods',
              label: 'Pseudopods',
              terminal: false,
            },
            {
              id: 'circles',
              label: 'Circles',
              terminal: false,
            },
            {
              id: 'clods',
              label: 'Clods',
              terminal: false,
            },
            {
              id: 'dots',
              label: 'Dots',
              terminal: false,
            },
            {
              id: 'structureless',
              label: 'Structureless',
              terminal: false,
            },
            {
              id: 'blood_vessels_dots',
              label: 'Blood vessels dots',
              terminal: false,
            },
            {
              id: 'blood_vessels_clods',
              label: 'Blood vessels clods',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_straight',
              label: 'Blood vessels lines straight',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_curved',
              label: 'Blood vessels lines curved',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_looped',
              label: 'Blood vessels lines looped',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_serpentine',
              label: 'Blood vessels lines serpentine',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_helical',
              label: 'Blood vessels lines helical',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_coiled',
              label: 'Blood vessels lines coiled',
              terminal: false,
            },
            {
              id: 'blood_vessels_lines_branched',
              label: 'Blood vessels lines branched',
              terminal: false,
            },
          ],
          selected: null,
          completed: false,
          display_info: false,
        },
      ],
    })),
  };
};

interface AnnotationContextInterface {
  annotation: any;
  getAnnotation: (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => void;
  updateAnnotation: (alertCallback: (message: string) => void) => void;
  currentAnnotationStep: any;
  currentAnnotationStepIndex: number;
  annotationLength: number;
  tempUpdateAnnotation: (updatedAnnotationStep: any) => void;
  getPreviousAnnotationStep: () => void;
  getNextAnnotationStep: () => void;
  currentAction: any;
  setCurrentAction: Dispatch<SetStateAction<any>>;
  saveCoordinates: (coordinates: any, annotoriousFormat: any) => void;
  updateCoordinates: (coordinates: any, annotoriousFormat: any) => void;
  currentAnnotation: any;
  annotationExists: boolean;
  currentTime: number;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  originalAnnotation: any;
  showPatches: boolean;
  setShowPatches: Dispatch<SetStateAction<boolean>>;
}

export const AnnotationContext = createContext<AnnotationContextInterface>({
  annotation: null,
  getAnnotation: () => undefined,
  updateAnnotation: () => undefined,
  currentAnnotationStep: null,
  currentAnnotationStepIndex: -1,
  annotationLength: 0,
  tempUpdateAnnotation: () => undefined,
  getPreviousAnnotationStep: () => undefined,
  getNextAnnotationStep: () => undefined,
  currentAction: null,
  setCurrentAction: () => undefined,
  saveCoordinates: () => undefined,
  updateCoordinates: () => undefined,
  currentAnnotation: null,
  annotationExists: false,
  currentTime: 0,
  setCurrentTime: () => undefined,
  originalAnnotation: null,
  showPatches: true,
  setShowPatches: () => undefined,
});

export const AnnotationProvider = ({ children }: any) => {
  const [annotation, setAnnotation] = useState<any>();
  const [annotationExists, setAnnotationExists] = useState(false);
  const [annotationLength, setAnnotationLength] = useState(0);
  const [currentAnnotationStep, setCurrentAnnotationStep] = useState<any>();
  const [currentAnnotationStepIndex, setCurrentAnnotationStepIndex] = useState(0);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentAnnotation, setCurrentAnnotation] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [originalAnnotation, setOriginalAnnotation] = useState<any>(null);
  const [showPatches, setShowPatches] = useState<boolean>(true);

  useEffect(() => {
    if (!annotation) return;
    const newAnnotationStep = { ...annotation.annotation_tree.steps[currentAnnotationStepIndex] };
    if (newAnnotationStep.template.length === 0) {
      newAnnotationStep.completed = true;
    }
    setCurrentAnnotationStep(newAnnotationStep);
    setAnnotationLength(annotation.annotation_tree.steps.length);
  }, [annotation]);

  useEffect(() => {
    if (!annotation) return;
    console.log('currentAnnotationStepIndex changed: ' + currentAnnotationStepIndex);
    console.log('annotation: ' + annotation);
    const newAnnotationStep = { ...annotation.annotation_tree.steps[currentAnnotationStepIndex] };
    if (newAnnotationStep.template.length === 0) {
      newAnnotationStep.completed = true;
    }
    setCurrentAnnotationStep(newAnnotationStep);
  }, [currentAnnotationStepIndex]);

  const saveCoordinates = (coordinates: any, annotoriousFormat: any) => {
    setCurrentAnnotation({ coordinates, annotoriousFormat });
    setCurrentAction(null);
  };

  const updateCoordinates = (coordinates: any, annotoriousFormat: any) => {
    setCurrentAnnotationStep((currentAnnotationStep: any) => {
      if (currentAnnotationStep.type !== 'annotation') return currentAnnotationStep;
      const templateIndex = currentAnnotationStep.template.findIndex((temp: any) => {
        return temp.annotoriousFormat?.id === annotoriousFormat.id;
      });
      if (templateIndex === -1) return currentAnnotationStep;
      const updatedAnnotationStep = { ...currentAnnotationStep };
      updatedAnnotationStep.template[templateIndex].coordinates = coordinates;
      updatedAnnotationStep.template[templateIndex].annotoriousFormat = annotoriousFormat;
      return updatedAnnotationStep;
    });
  };

  const getAnnotation = async (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`/stage/${stageId}/sequence/${sequenceId}/annotation`);
      setAnnotation(res.data);
      setAnnotationExists(true);
      setOriginalAnnotation(res.data);
      setAnnotationLength(res.data.annotation_tree.steps.length);
      setCurrentAnnotationStepIndex(0);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          const res_seq = await axios.get(`/sequence/${sequenceId}`);

          const annotationTemplate = {
            annotation_tree: JSON.parse(JSON.stringify(parentTemplate(res_seq.data.patches))),
            time: 0,
            sequence_id: sequenceId,
            stage_id: stageId,
          };

          setAnnotation(annotationTemplate);
          setAnnotationExists(false);
          setOriginalAnnotation(annotationTemplate);
          setAnnotationLength(annotationTemplate.annotation_tree.steps.length);
          setCurrentAnnotationStepIndex(0);
          return;
        }
      }
      alertCallback('An error occured while fetching annotation');
      console.error(err);
    }
  };

  const tempUpdateAnnotation = (updatedAnnotationStep: any) => {
    const tempAnnotation = { ...annotation };
    tempAnnotation.annotation_tree.steps[currentAnnotationStepIndex] = updatedAnnotationStep;

    setAnnotation(tempAnnotation);
  };

  const updateAnnotation = async (alertCallback: (message: string) => void) => {
    try {
      if (annotationExists) {
        await axios.put(
          `/annotation/${annotation.id}`,
          JSON.stringify({
            stage_id: annotation.stage_id,
            sequence_id: annotation.sequence_id,
            time: currentTime,
            annotation_tree: annotation.annotation_tree,
          })
        );
      } else {
        await axios.post('/annotation', JSON.stringify({ ...annotation, time: currentTime }));
        //setAnnotationExists(true);
      }
    } catch (err) {
      console.error(err);
      alertCallback('An error occured while saving annotation');
    }
  };

  const getNextAnnotationStep = () => {
    setCurrentAnnotationStepIndex(currentAnnotationStepIndex + 1);
  };

  const getPreviousAnnotationStep = () => {
    // reset current annotation step
    const newAnnotationStep = { ...currentAnnotationStep };
    newAnnotationStep.completed = false;
    for (const category of newAnnotationStep.template) {
      category.coordinates = [];
      category.annotoriousFormat = null;
      category.selected = null;
      category.completed = false;
    }
    tempUpdateAnnotation(newAnnotationStep);
    setCurrentAnnotationStepIndex(currentAnnotationStepIndex - 1);
  };

  const value = {
    annotation,
    getAnnotation,
    updateAnnotation,
    annotationLength,
    currentAnnotationStep,
    currentAnnotationStepIndex,
    tempUpdateAnnotation,
    getPreviousAnnotationStep,
    getNextAnnotationStep,
    currentAction,
    setCurrentAction,
    saveCoordinates,
    currentAnnotation,
    annotationExists,
    currentTime,
    setCurrentTime,
    originalAnnotation,
    updateCoordinates,
    showPatches,
    setShowPatches,
  };

  return <AnnotationContext.Provider value={value}>{children}</AnnotationContext.Provider>;
};
