import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { SequencesContext } from '../../context/SequencesContext';
import AccordionWrapper from '../Common/AccordionWrapper';
import SequenceInfoBox from './SequenceInfoBox';
const QuestionnaireRadio = ({ level, updateAnnotation, questionnaireType }: any) => {
  const { sequence } = useContext(SequencesContext);

  const { label, options, selected, completed } = level;
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (!sequence) return;

    handleAccordionChange(!sequence.completed);
  }, [sequence]);

  const handleAccordionChange = (expand: boolean) => {
    setIsExpanded(expand && !sequence?.completed);
  };

  const handleUpdateAnnotation = (selected: any) => {
    const newLevel = { ...level };
    newLevel.selected = selected;
    newLevel.completed = true;

    updateAnnotation(newLevel);
  };
  const handleSelectOption = (event: ChangeEvent<HTMLInputElement>) => {
    return null;
    //handleUpdateAnnotation((event.target as HTMLInputElement).value);
    //
    //
    //
  };
  return (
    <>
      {level.display_info ? <SequenceInfoBox /> : null}
      <AccordionWrapper
        title={label}
        isExpanded={isExpanded}
        handleChange={(event: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
          handleAccordionChange(expanded)
        }
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selected}
            onChange={handleSelectOption}
          >
            {options.map((option: any) => (
              <FormControlLabel
                label={
                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
                    {option.label}
                  </Typography>
                }
                key={option.id}
                value={option.id}
                control={
                  <Radio
                    color={completed && questionnaireType === 'ground_truth_annotation' ? 'success' : 'primary'}
                    onClick={() => {
                      handleUpdateAnnotation(option.id);
                    }}
                    size="small"
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AccordionWrapper>
    </>
  );
};

export default QuestionnaireRadio;
