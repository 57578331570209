import { AccountCircleRounded } from '@mui/icons-material';
import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import COLORS from '../../constants/colors.json';
import { formatTimeHrMin, formatTimeMinSec, getLastActive } from '../../utils/datetime';

const UserProgress = ({ users, sequences }: any) => {
  const progressPercentage = (userAnnotations: number) => {
    return Math.round((userAnnotations / sequences) * 100);
  };

  const getAvatarBgColor = (user: any) => {
    return COLORS[user.id % COLORS.length];
  };

  return (
    <>
      <Card sx={{ width: '100%', height: '52.5vh' }}>
        <TableContainer component={Paper} sx={{ height: '100%' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align="right">Progress</TableCell>
                <TableCell align="right">Time per Sequence</TableCell>
                <TableCell align="right">Total Time</TableCell>
                <TableCell align="right">Last active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: 'auto' }}>
              {users.map((user: any) => (
                <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" sx={{ display: 'flex', margin: 'auto' }}>
                    <AccountCircleRounded fontSize="small" sx={{ marginRight: 1 }} />
                    {user.username}
                  </TableCell>
                  <TableCell align="right">{progressPercentage(user.annotations)}%</TableCell>
                  <TableCell align="right">{formatTimeMinSec(user.time / user.annotations)}</TableCell>
                  <TableCell align="right">
                    {typeof user.time == 'string' ? user.time : formatTimeHrMin(user.time)}
                  </TableCell>
                  <TableCell align="right">{getLastActive(user.last_active)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default UserProgress;
