import { Alert, Button, Card, CardMedia, Grid, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
// import M3i from '../assets/M3i logo plain.png';
import Zeiss from '../assets/magnosco_logo.png';
import { AuthContext } from '../context/AuthContext';
import { CommonContext } from '../context/CommonContext';

const defaultFormFields = {
  username: '',
  password: '',
};

const Login = () => {
  const { login, isAuthenticated } = useContext(AuthContext);
  const { setAlertMessage, setLoading } = useContext(CommonContext);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { username, password } = formFields;

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    try {
      await login(username, password);
    } catch (err) {
      setAlertMessage('Wrong Credentials');
    }
    resetFormFields();
    setLoading(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const isMobile = width <= 768;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: '80vh',
      }}
    >
      <Grid item>
        <Card
          sx={{
            maxWidth: 400,
            minWidth: 300,
            padding: 3,
          }}
        >
          <CardMedia
            component="img"
            image={Zeiss}
            title="M3i"
            sx={{
              paddingBottom: 6,
              paddingTop: 6,
              paddingX: 2,
            }}
          />
          {/* <CardMedia
            component="img"
            image={M3i}
            title="M3i"
            sx={{
              marginBottom: 5,
              width: '80&',
            }}
          /> */}
          <form onSubmit={handleSubmit}>
            {isMobile && (
              <Alert severity="warning">
                The platform has not been optimised for mobile devices. Please consider using a device with a larger
                screen!
              </Alert>
            )}
            <TextField
              label="Username"
              onChange={handleChange}
              value={username}
              name="username"
              InputLabelProps={{ required: false }}
              required
              fullWidth
              margin="dense"
            />
            <TextField
              label="Password"
              onChange={handleChange}
              value={password}
              name="password"
              type="password"
              InputLabelProps={{ required: false }}
              required
              fullWidth
              margin="dense"
            />
            <Button
              type="submit"
              variant="outlined"
              // color="inherit"
              fullWidth
              sx={{
                marginTop: 2,
              }}
            >
              Login
            </Button>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
