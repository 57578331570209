import { Box, Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnnotationContext } from '../../context/AnnotationContext';
import { CommonContext } from '../../context/CommonContext';
import { SequencesContext } from '../../context/SequencesContext';
import AlertDialog from '../Common/AlertDialog';
import Questionnaire from './Questionnaire';

const AnnotationContainer = () => {
  const navigate = useNavigate();
  const { id, stageId, sequenceId } = useParams();

  const {
    setCurrentAction,
    currentAnnotation,
    annotationExists,
    getAnnotation,
    currentAnnotationStep,
    annotationLength,
    currentAnnotationStepIndex,
    tempUpdateAnnotation,
    updateAnnotation,
    getPreviousAnnotationStep,
    getNextAnnotationStep,
  } = useContext(AnnotationContext);
  const { getNextIncompleteSequence, getNextSequence, sequence, setMainImage } = useContext(SequencesContext);
  const { setAlertMessage } = useContext(CommonContext);

  const annotationContainerBoxRef = useRef<any>(null);
  const annotationButtonsBoxRef = useRef<any>(null);

  const [tempAnnotationStep, setTempAnnotationStep] = useState<any>();

  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [lockAlertDialogText, setLockAlertDialogText] = useState('');
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    getAnnotation(Number(stageId), Number(sequenceId), setAlertMessage);
  }, [stageId, sequenceId]);

  useEffect(() => {
    setTempAnnotationStep(currentAnnotationStep);
    scrollToBottom();
  }, [currentAnnotationStep]);

  useEffect(() => {
    scrollToBottom();
  }, [
    annotationContainerBoxRef?.current?.clientHeight,
    annotationExists,
    tempAnnotationStep,
    currentAnnotationStepIndex,
  ]);

  const scrollToBottom = () => {
    annotationButtonsBoxRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTempAnnotationStepUpdate = (updatedAnnotationStep: any) => {
    tempUpdateAnnotation(updatedAnnotationStep);
  };

  const handleUpdateAnnotation = () => {
    if (currentAnnotationStepIndex === annotationLength - 1) {
      //updateAnnotation(setAlertMessage);
      //nextSequence();
      handleLockClick();
    } else {
      getNextAnnotationStep();
      if (currentAnnotationStepIndex === 0) {
        setMainImage();
        //navigate(`/projects/${id}/stages/${stageId}/sequences/${sequence.id}/images/${sequence.main_image_id}`);
      }
    }
  };

  const nextSequence = () => {
    console.log('nextSequence()');
    navigate(`/projects/${id}`);
    const nextSequence = getNextIncompleteSequence();
    console.log('nextSequence(): nextSequence');
    console.log(nextSequence);
    if (!nextSequence) {
      navigate(`/projects/${id}`);
    } else {
      navigate(`/projects/${id}/stages/${stageId}/sequences/${nextSequence.id}/images`);
      //if (nextSequence.completed) {
      //  //setMainImage();
      //  navigate(`/projects/${id}/stages/${stageId}/sequences/${nextSequence.id}/images/${nextSequence.main_image_id}`);
      //} else {
      //  navigate(`/projects/${id}/stages/${stageId}/sequences/${nextSequence.id}/images`);
      //}
    }
  };

  const resetLowerLevels = (newAnnotation: any, updatedLevel: any) => {
    const updatedLevelIndex = newAnnotation.findIndex((criteria: any) => criteria.id === updatedLevel.id);
    for (let i = updatedLevelIndex + 1; i < newAnnotation.length; i++) {
      newAnnotation[i].completed = false;
      newAnnotation[i].selected = null;
      newAnnotation[i].coordinates = [];
      newAnnotation[i].annotoriousFormat = null;
      if (newAnnotation[i].rows) {
        for (const row of newAnnotation[i].rows) {
          row.selected = null;
        }
      }
    }
    return newAnnotation;
  };

  const renderAnnotationStep = () => {
    switch (tempAnnotationStep?.type) {
      case 'questionnaire':
        return (
          <Questionnaire
            questionnaireTemplate={tempAnnotationStep}
            handleAnnotationUpdate={handleTempAnnotationStepUpdate}
            setCurrentAction={setCurrentAction}
            currentAnnotation={currentAnnotation}
            annotationExists={annotationExists}
            resetLowerLevels={resetLowerLevels}
            questionnaireType={'user_annotation'}
            getAssociatedPolygonsWithLevel={() => {
              return null;
            }}
            currentAction={null}
          />
        );
      case 'annotation':
        return (
          <Questionnaire
            questionnaireTemplate={tempAnnotationStep}
            handleAnnotationUpdate={handleTempAnnotationStepUpdate}
            setCurrentAction={setCurrentAction}
            currentAnnotation={currentAnnotation}
            annotationExists={annotationExists}
            resetLowerLevels={resetLowerLevels}
            questionnaireType={'user_annotation'}
            getAssociatedPolygonsWithLevel={() => {
              return null;
            }}
            currentAction={null}
          />
        );
      default:
        return <Typography>Annotation type not implemented yet</Typography>;
    }
  };
  const handleCloseLockAlertDialog = () => {
    setOpenLockAlertDialog(false);
  };
  const handleBackClick = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText(
      'Please confirm you want to perform this action. This will remove any annotations might have made for this case.'
    );
    setAlertType('backClick');
  };

  const handleLockClick = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText(
      'Please confirm you want to perform this action. This will lock the current case and you will not be able to make any corrections.'
    );
    setAlertType('lockClick');
  };

  const nextCase = () => {
    updateAnnotation(setAlertMessage);
    nextSequence();
  };
  return (
    <Paper
      sx={{
        height: '90vh',
        overflowY: 'auto',
      }}
      elevation={3}
    >
      <Box m={1} ref={annotationContainerBoxRef}>
        {renderAnnotationStep()}
        <Box m={1} ref={annotationButtonsBoxRef}>
          {sequence?.completed ? (
            <Box sx={{ display: 'flex' }}>
              <Typography typography="body2">
                Annotation has been locked and completed. Please navigate to the next case.
              </Typography>
              <Button color="success" onClick={nextSequence} sx={{ ml: 'auto' }} variant="contained">
                Next case
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>{currentAnnotationStepIndex > 0 && <Button onClick={handleBackClick}>Back</Button>}</Box>
              <Box>
                {tempAnnotationStep?.completed && (
                  <Button color="success" onClick={handleUpdateAnnotation}>
                    {currentAnnotationStepIndex === annotationLength - 1 ? 'Lock and Complete' : 'Continue'}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={() => {
          setOpenLockAlertDialog(false);
          if (alertType === 'backClick') getPreviousAnnotationStep();
          if (alertType === 'lockClick') nextCase();
        }}
        title="Alert"
        text={lockAlertDialogText}
      />
    </Paper>
  );
};

export default AnnotationContainer;
