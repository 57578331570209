import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AnnotationProvider } from './context/AnnotationContext';
import { GroundTruthAnnotationProvider } from './context/GroundTruthAnnotationContext';
import { AuthProvider } from './context/AuthContext';
import { CommonProvider } from './context/CommonContext';
import { ProjectsProvider } from './context/ProjectsContext';
import { SequencesProvider } from './context/SequencesContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <CommonProvider>
        <ProjectsProvider>
          <SequencesProvider>
            <AnnotationProvider>
              <GroundTruthAnnotationProvider>
                <App />
              </GroundTruthAnnotationProvider>
            </AnnotationProvider>
          </SequencesProvider>
        </ProjectsProvider>
      </CommonProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
