import { Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AnnotationContext } from '../../context/AnnotationContext';

const Stopwatch = () => {
  const { originalAnnotation, currentTime, setCurrentTime, annotationExists } = useContext(AnnotationContext);

  useEffect(() => {
    if (!originalAnnotation) return;
    let sequenceTime = originalAnnotation.time;
    setCurrentTime(sequenceTime);
    if (annotationExists) return;
    const interval: NodeJS.Timer = setInterval(() => {
      sequenceTime += 1000;
      setCurrentTime(sequenceTime);
    }, 1000);
    // returned function will be called on component unmount
    return () => {
      setCurrentTime(0);
      clearInterval(interval);
    };
  }, [originalAnnotation]);

  return (
    <div className="stopwatch">
      <div className="numbers">
        <Typography variant="body2">
          {
            //`0${Math.floor(currentTime / 60000)}`.slice(-2)}:{`0${Math.floor((currentTime / 1000) % 60)}`.slice(-2)
          }
        </Typography>
      </div>
    </div>
  );
};

export default Stopwatch;
