import axios from 'axios';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CVATtoRoundRobinlabelConverter, annotationMetaData, omitLabels } from '../utils/annotationImport';
import { parse } from '../utils/fileUtils';
import { AuthContext } from './AuthContext';
import { CommonContext } from './CommonContext';
const isHiddenFile = (filename: string) => {
  return /^\./.test(filename);
};
const generateAnnotoriousTemplate = (points: any, label: string, color: string, coordinateId: string) => {
  const pointsString = points.map((point: any) => {
    return point.x.toString() + ',' + point.y.toString();
  });
  return {
    '@context': 'http://www.w3.org/ns/anno.jsonld',
    type: 'Annotation',
    body: [
      {
        type: 'TextualBody',
        purpose: 'tagging',
        value: label,
        color: color,
      },
    ],
    target: {
      source: '',
      selector: {
        type: 'SvgSelector',
        value: '<svg><polygon points="' + pointsString + '"></polygon></svg>',
      },
    },
    id: coordinateId,
  };
};

const extractPolygons = async (image: any) => {
  const imagePolygons = image.getElementsByTagName('polygon');
  const polygons: any = [];

  for (const polygon of imagePolygons) {
    let points = polygon.getAttribute('points');
    let id = polygon.getAttribute('label');
    if (id in omitLabels) continue;
    id = CVATtoRoundRobinlabelConverter[id];
    points = points.split(';');
    points = points.map((point: string) => {
      return {
        x: parseInt(point.split(',')[0]),
        y: parseInt(point.split(',')[1]),
      };
    });

    const coordinateId = Math.random().toString(36).substring(2, 11);
    const insidePolygon = polygons.find((_polygon: any) => _polygon.id === id);

    if (!insidePolygon) {
      const insidePolygon: any = {
        id: id,
        type: annotationMetaData[id].type,
        label: annotationMetaData[id].label,
        color: annotationMetaData[id].color,
        options: annotationMetaData[id].options,
        selected: 1,
        coordinates: {},
        annotoriousFormats: {},
      };
      insidePolygon.coordinates[coordinateId] = points;
      insidePolygon.annotoriousFormats[coordinateId] = generateAnnotoriousTemplate(
        points,
        id,
        annotationMetaData[id].color,
        coordinateId
      );
      polygons.push(insidePolygon);
    } else {
      insidePolygon.coordinates[coordinateId] = points;
      insidePolygon.annotoriousFormats[coordinateId] = generateAnnotoriousTemplate(
        points,
        id,
        annotationMetaData[id].color,
        coordinateId
      );
    }
  }
  return polygons;
};

const generateStages = async (stagesFile: File) => {
  const stages: any[] = [];
  const tempStages: any[] = await parse(stagesFile);
  for (const tempStage of tempStages) {
    stages.push({
      name: tempStage.name,
      info: tempStage.info,
      tempId: Number(tempStage.id),
    });
  }
  return stages;
};

const generateSequences = async (sequencesFile: File) => {
  const sequences: any[] = [];
  const tempSequences: any[] = await parse(sequencesFile);
  for (const tempSequence of tempSequences) {
    const unformattedPatches = tempSequence.patches.split('][');
    let isErrorPatches = false;
    let patches = unformattedPatches.map((unformattedPatch: any) => {
      const [x, y] = unformattedPatch.replace(/[[\]]/g, '').split(',');

      if (!x || !y) isErrorPatches = true;

      return {
        x,
        y,
      };
    });

    if (isErrorPatches) patches = null;

    sequences.push({
      name: tempSequence.name,
      info: tempSequence.info,
      tempId: Number(tempSequence.id),
      tempStageIds: tempSequence.stages.split(',').map((stageId: any) => Number(stageId)),
      tempMainImage: tempSequence.main_image,
      patches,
    });
  }
  return sequences;
};

const generateGroundTruthAnnotations = async (files: File[], sequences: any[]) => {
  const annotations: any[] = [];
  const sequencesCounts: any = {};
  for (const file of files) {
    if (file.name === 'sequences.csv') continue;
    if (file.name === 'stages.csv') continue;
    if (file.name.split('.').pop() === 'jpg') continue;
    const filepath = file.webkitRelativePath;
    const filepathArray = filepath.split('/');
    const sequenceName = filepathArray[1];
    const annotationsSequence = sequences.find((sequence: any) => sequence.name === sequenceName);
    if (!annotationsSequence) continue;
    if (sequencesCounts[sequenceName]) sequencesCounts[sequenceName]++;
    else sequencesCounts[sequenceName] = 1;
    annotations.push({
      filename: file.name,
      order: sequencesCounts[sequenceName],
      tempSequenceId: annotationsSequence.tempId,
      filepath: file.webkitRelativePath,
      file: file,
    });
  }
  return annotations;
};

const generateImages = async (files: File[], sequences: any[]) => {
  const images: any[] = [];
  const sequencesCounts: any = {};
  const allowedExtension = ['jpg', 'png', 'jpeg'];
  for (const file of files) {
    if (isHiddenFile(file.name)) continue;
    const fileExtension = file.name.split('.').pop();
    if (fileExtension) if (!allowedExtension.includes(fileExtension)) continue;
    const filepath = file.webkitRelativePath;
    const filepathArray = filepath.split('/');
    const sequenceName = filepathArray[1];
    const imageSequence = sequences.find((sequence: any) => sequence.name === sequenceName);
    if (!imageSequence) continue;
    if (sequencesCounts[sequenceName]) sequencesCounts[sequenceName]++;
    else sequencesCounts[sequenceName] = 1;

    images.push({
      filename: file.name,
      order: sequencesCounts[sequenceName],
      tempSequenceId: imageSequence.tempId,
      filepath: file.webkitRelativePath,
    });
  }
  return images;
};

const uploadImageToS3WithPresignedUrl = async (imageData: any, file: File) => {
  const formData = new FormData();
  Object.keys(imageData.s3_info.fields).forEach((key: string) => {
    formData.append(key, imageData.s3_info.fields[key]);
  });
  formData.append('file', file);

  const config = {
    headers: {
      Authorization: '',
    },
  };
  delete axios.defaults.headers.common['content-type'];
  await axios.post(imageData.s3_info.url, formData, config);
  axios.defaults.headers.common['content-type'] = 'application/json';
};

const combineQuestionnairesAndPolygons = async (
  groundTruthAnnotationsQuestionnaires: any,
  groundTruthAnnotationsPolygons: any,
  sequences: any
) => {
  const groundTruthAnnotations: any = {};
  for (const Q of groundTruthAnnotationsQuestionnaires) {
    const polygon = groundTruthAnnotationsPolygons.find((polygon: any) => polygon.tempSequenceId === Q.tempSequenceId);
    groundTruthAnnotations[polygon.tempSequenceId] = {
      steps: [
        Q.questionnaire,
        {
          id: 2,
          type: 'annotation',
          template: polygon.polygons,
          styles: ['polygon'],
          mainImage: true,
          completed: false,
          description:
            'You already indicated that the following cellular and tissue features were visible among the entire case. Now please specify whether those cellular and tissue features are also visible in the current image that is part of this case.',
        },
      ],
    };
  }
  return groundTruthAnnotations;
};

interface ProjectsContextInterface {
  projects: any[];
  stages: any[];
  recentProjects: any[];
  openAddDialog: boolean;
  openAddCasesDialog: boolean;
  openProjectAccessDialog: boolean;
  setOpenProjectAccessDialog: Dispatch<SetStateAction<boolean>>;
  setOpenAddDialog: Dispatch<SetStateAction<boolean>>;
  setOpenAddCasesDialog: Dispatch<SetStateAction<boolean>>;
  openAllDialog: boolean;
  setOpenAllDialog: Dispatch<SetStateAction<boolean>>;
  createProject: (
    project: any,
    alertCallback: (message: string) => void,
    progressCallback: (message: string) => void
  ) => void;
  addCasesToProject: (
    cases: any,
    alertCallback: (message: string) => void,
    progressCallback: (message: string) => void
  ) => void;
  removeProjects: (projectIds: number[], alertCallback: (message: string) => void) => void;
  project: any;
  projectAccess: any;
  getProjects: () => void;
  getProject: (id: number) => void;
  getStages: (id: number, alertCallback: (message: string) => void) => void;
  currentStage: any;
  updateProject: (updatedProject: any, alertCallback: (message: string) => void) => void;
  updateUserProjectAccess: (userProjectAccess: any) => void;
  getDashboard: (id: number, alertCallback: (message: string) => void) => void;
  dashboard: any;
  exportProject: (id: number, finishCallback: () => void) => void;
}

export const ProjectsContext = createContext<ProjectsContextInterface>({
  projects: [],
  stages: [],
  recentProjects: [],
  openAddDialog: false,
  openAddCasesDialog: false,
  openProjectAccessDialog: false,
  setOpenProjectAccessDialog: () => undefined,
  setOpenAddDialog: () => undefined,
  setOpenAddCasesDialog: () => undefined,
  updateUserProjectAccess: () => undefined,
  openAllDialog: false,
  setOpenAllDialog: () => undefined,
  createProject: () => undefined,
  addCasesToProject: () => undefined,
  removeProjects: () => undefined,
  project: null,
  projectAccess: null,
  getProjects: () => undefined,
  getProject: () => undefined,
  getStages: () => undefined,
  currentStage: null,
  updateProject: () => undefined,
  getDashboard: () => undefined,
  dashboard: null,
  exportProject: () => undefined,
});

export const ProjectsProvider = ({ children }: any) => {
  const { setLoadingText } = useContext(CommonContext);
  const navigate = useNavigate();
  const [project, setProject] = useState();
  const [projectAccess, setProjectAccess] = useState<any>();
  const [projects, setProjects] = useState<any[]>([]);
  const [recentProjects, setRecentProjects] = useState<any[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openAddCasesDialog, setOpenAddCasesDialog] = useState(false);
  const [openProjectAccessDialog, setOpenProjectAccessDialog] = useState(false);
  const [openAllDialog, setOpenAllDialog] = useState(false);
  const [stages, setStages] = useState<any[]>([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [dashboard, setDashboard] = useState(null);
  const { isAnnotator, isAdmin, isAnalyst } = useContext(AuthContext);
  useEffect(() => {
    setRecentProjects(
      projects.slice(0, 6).sort((c1: any, c2: any) => {
        return +new Date(c2.last_updated) - +new Date(c1.last_updated);
      })
    );
  }, [projects]);

  useEffect(() => {
    setCurrentStage(stages[0]);
  }, [stages]);

  const getProjects = async () => {
    try {
      const res = await axios.get('/projects');
      setProjects(res.data.projects);
    } catch (err) {
      console.error(err);
    }
  };

  const getProject = async (id: number) => {
    try {
      const res = await axios.get(`/project/${id}`);
      setProject(res.data);
      getProjectAccess(id);
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectAccess = async (id: number) => {
    try {
      if (isAdmin() || isAnalyst()) {
        const res = await axios.get(`/project/${id}/access`);
        setProjectAccess(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateUserProjectAccess = async (userProjectAccess: any) => {
    try {
      let res;
      const upa = { ...userProjectAccess };
      upa['user_id'] = upa['user']['id'];
      delete upa['user'];
      if (Object.keys(upa).includes('id')) {
        res = await axios.put(`/user_project_access/${upa['id']}`, JSON.stringify(upa));
      } else {
        res = await axios.post('/user_project_access', JSON.stringify(upa));
      }
      const updatedProjectAccess = { ...projectAccess };
      for (const accessIdx in updatedProjectAccess.access) {
        if (
          updatedProjectAccess.access[accessIdx].user_id === upa.user_id &&
          updatedProjectAccess.access[accessIdx].project_id === upa.project_id
        ) {
          updatedProjectAccess.access[accessIdx] = upa;
        }
      }
      setProjectAccess(updatedProjectAccess);
    } catch (err) {
      console.error(err);
    }
  };

  const exportProject = async (id: number, finishCallback: () => void) => {
    try {
      const res = await axios.get(`/project/${id}/export`, {
        responseType: 'blob',
        headers: {
          'Content-Disposition': 'attachment; filename="export.zip"',
        },
      });

      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.zip');
      document.body.appendChild(link);
      link.click();
      //saveAs(res.data, 'export.zip');
      finishCallback();
    } catch (err) {
      console.error(err);
    }
  };

  const addCasesToProject = async (
    newCases: any,
    alertCallback: (message: string) => void,
    progressCallback: (message: string) => void
  ) => {
    progressCallback('Reading files');
    const files = newCases.files;
    if (!files || files.length === 0) alertCallback('No files found');
    if (!files.find((file: File) => file.name === 'sequences.csv')) alertCallback('No sequences.csv found');

    const sequences = await generateSequences(files.find((file: File) => file.name === 'sequences.csv'));
    const images = await generateImages(files, sequences);

    for (const sequence of sequences) {
      const main_image = images.find((image: any) => image.filename === sequence.tempMainImage);
      if (!main_image) {
        alertCallback('Main image for ' + sequence.name + ' not found (expected: ' + sequence.tempMainImage + ')');
        return;
      }

      if (!sequence.patches) {
        alertCallback(
          `Patches for ${sequence.name} could not be parsed (expected format: [123,321][231,312][111,222])`
        );
        return;
      }
    }

    try {
      const createdSequences: any[] = [];
      const createdImages = [];
      let res;
      progressCallback('Uploading sequence meta data');
      for (const sequence of sequences) {
        sequence.stage_ids = [stages[0].id]; // ??
        res = await axios.post(
          '/sequence',
          JSON.stringify({
            name: sequence.name,
            info: sequence.info,
            stage_ids: sequence.stage_ids,
            patches: sequence.patches,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading case ' + sequence.name);
          return;
        }
        createdSequences.push({ ...sequence, ...res.data });
      }

      let i = 1;

      for (const image of images) {
        progressCallback('Uploading images ' + i + ' of ' + images.length + '; ' + image.filename);
        const sequence = createdSequences.find((sequence: any) => sequence.tempId === image.tempSequenceId);
        if (!sequence) {
          alertCallback(
            'Could not determine to what sequence does image with temp id ' + image.tempSequenceId + ' belong to'
          );
          return;
        }
        image.sequence_id = sequence.id;
        res = await axios.post(
          '/image',
          JSON.stringify({
            filename: image.filename,
            order: image.order,
            sequence_id: image.sequence_id,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading image ' + image.filename);
          return;
        }
        const newImage = { ...image, ...res.data };
        try {
          await uploadImageToS3WithPresignedUrl(
            newImage,
            files.find((file: File) => file.webkitRelativePath === newImage.filepath)
          );
        } catch (err) {
          console.error(err);
        }
        i += 1;
        createdImages.push(newImage);
      }

      for (const sequence of createdSequences) {
        const image = createdImages.find(
          (image: any) => image.sequence_id === sequence.id && image.filename === sequence.tempMainImage
        );

        if (!image) {
          alertCallback(
            'Main image for ' + sequence.name + ' not found found. Expecting a file: ' + sequence.tempMainImage
          );
          return;
        }
        sequence.main_image_id = image.id;

        res = await axios.put(
          `/sequence/${sequence.id}`,
          JSON.stringify({
            name: sequence.name,
            info: sequence.info,
            stage_ids: sequence.stage_ids,
            main_image_id: sequence.main_image_id,
            patches: sequence.patches,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading case ' + sequence.name);
          return;
        }
      }
    } catch (err) {
      alertCallback('Error occured when trying to add cases to project: ' + err);
      return;
    }
    window.location.reload();
  };

  const createProject = async (
    newProject: any,
    alertCallback: (message: string) => void,
    progressCallback: (message: string) => void
  ) => {
    progressCallback('Reading files');
    const files = newProject.files;
    if (!files || files.length === 0) alertCallback('No files found');
    if (!files.find((file: File) => file.name === 'stages.csv')) alertCallback('No stages.csv found');
    if (!files.find((file: File) => file.name === 'sequences.csv')) alertCallback('No sequences.csv found');

    const tempProject = {
      name: newProject.name,
      info: newProject.info,
    };
    const stages = await generateStages(files.find((file: File) => file.name === 'stages.csv'));
    const sequences = await generateSequences(files.find((file: File) => file.name === 'sequences.csv'));
    const images = await generateImages(files, sequences);

    for (const sequence of sequences) {
      const main_image = images.find((image: any) => image.filename === sequence.tempMainImage);
      if (!main_image) {
        alertCallback('Main image for ' + sequence.name + ' not found (expected: ' + sequence.tempMainImage + ')');
        return;
      }

      if (!sequence.patches) {
        alertCallback(
          `Patches for ${sequence.name} could not be parsed (expected format: [123,321][231,312][111,222])`
        );
        return;
      }
    }

    try {
      let createdProject: any = {};
      const createdStages: any[] = [];
      const createdSequences: any[] = [];
      const createdImages = [];
      let res = await axios.post('/project', JSON.stringify(tempProject));
      createdProject = res.data;
      setProjects([createdProject, ...projects]);
      for (const stage of stages) {
        stage.project_id = createdProject.id;
        res = await axios.post(
          '/stage',
          JSON.stringify({
            name: stage.name,
            info: stage.info,
            project_id: stage.project_id,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured creating stage ' + stage.name);
          return;
        }
        createdStages.push({ ...stage, ...res.data });
      }

      progressCallback('Uploading sequence meta data');
      for (const sequence of sequences) {
        sequence.stage_ids = sequence.tempStageIds.map((tempStageId: number) => {
          const sequenceStage = createdStages.find((stage: any) => stage.tempId === tempStageId);
          return sequenceStage.id;
        });

        res = await axios.post(
          '/sequence',
          JSON.stringify({
            name: sequence.name,
            info: sequence.info,
            stage_ids: sequence.stage_ids,
            patches: sequence.patches,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading case ' + sequence.name);
          return;
        }
        createdSequences.push({ ...sequence, ...res.data });
      }

      let i = 0;
      for (const image of images) {
        progressCallback('Uploading images ' + i + ' of ' + images.length + '; ' + image.filename);
        const sequence = createdSequences.find((sequence: any) => sequence.tempId === image.tempSequenceId);
        if (!sequence) {
          alertCallback(
            'Could not determine to what sequence does image with temp id ' + image.tempSequenceId + ' belong to'
          );
          return;
        }
        image.sequence_id = sequence.id;
        res = await axios.post(
          '/image',
          JSON.stringify({
            filename: image.filename,
            order: image.order,
            sequence_id: image.sequence_id,
          })
        );
        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading image ' + image.filename);
          return;
        }
        const newImage = { ...image, ...res.data };
        try {
          await uploadImageToS3WithPresignedUrl(
            newImage,
            files.find((file: File) => file.webkitRelativePath === newImage.filepath)
          );
        } catch (err) {
          console.log(res);
          alertCallback('Error occured uploading image ' + image.filename + ', error: ' + err);
          return;
        }
        i += 1;
        createdImages.push(newImage);
      }

      for (const sequence of createdSequences) {
        const image = createdImages.find(
          (image: any) => image.sequence_id === sequence.id && image.filename === sequence.tempMainImage
        );

        if (!image) {
          alertCallback(
            'Main image for ' + sequence.name + ' not found found. Expecting a file: ' + sequence.tempMainImage
          );
          return;
        }
        sequence.main_image_id = image.id;

        res = await axios.put(
          `/sequence/${sequence.id}`,
          JSON.stringify({
            name: sequence.name,
            info: sequence.info,
            stage_ids: sequence.stage_ids,
            main_image_id: sequence.main_image_id,
            patches: sequence.patches,
          })
        );

        if (![200, 201].includes(res.status)) {
          console.log(res);
          alertCallback('Error occured uploading case ' + sequence.name);
          return;
        }
      }
    } catch (err: any) {
      alertCallback('Error occured when trying to create the project: ' + err);
      console.error(err);
      return;
    }
  };

  const removeProjects = async (ids: number[], alertCallback: (message: string) => void) => {
    let newProjects = [...projects];
    try {
      for (const id of ids) {
        await axios.delete(`project/${id}`);
      }
      newProjects = newProjects.filter((project) => !ids.includes(project.id));
      setProjects(newProjects);
    } catch (err) {
      alertCallback('An error occured while deleting a project');
      console.log(err);
    }
  };

  const updateProject = async (updatedProject: any, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.put(
        `project/${updatedProject.id}`,
        JSON.stringify({
          name: updatedProject.name,
          info: updatedProject.info,
          locked: updatedProject.locked,
        })
      );
      setProject(updatedProject);
    } catch (err) {
      alertCallback('An error occured while updating the project');
      console.log(err);
    }
  };

  const getStages = async (id: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`project/${id}/stages`);
      setStages(res.data.stages);
    } catch (err) {
      alertCallback('An error occured while fetching project stages');
      console.log(err);
    }
  };

  const getDashboard = async (id: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`/project/${id}/dashboard`);
      const firstStageKey = Object.keys(res.data)[0];
      setDashboard(res.data[firstStageKey]);
    } catch (err) {
      alertCallback('An error occured while fetching project dashboard');
      console.error(err);
    }
  };

  const value = {
    project,
    projectAccess,
    projects,
    recentProjects,
    openAddDialog,
    openAddCasesDialog,
    openProjectAccessDialog,
    setOpenAddCasesDialog,
    openAllDialog,
    setOpenAddDialog,
    setOpenProjectAccessDialog,
    setOpenAllDialog,
    updateUserProjectAccess,
    createProject,
    removeProjects,
    stages,
    getProjects,
    getProject,
    getStages,
    currentStage,
    updateProject,
    getDashboard,
    dashboard,
    addCasesToProject,
    exportProject,
  };

  return <ProjectsContext.Provider value={value as ProjectsContextInterface}>{children}</ProjectsContext.Provider>;
};
