/* eslint-disable no-prototype-builtins */
import DeleteIcon from '@mui/icons-material/Delete';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import HideImageIcon from '@mui/icons-material/HideImage';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ImageIcon from '@mui/icons-material/Image';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import UndoIcon from '@mui/icons-material/Undo';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import { Box, IconButton, Paper, Slider, Typography, useTheme } from '@mui/material';
import Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import SelectorPack from '@recogito/annotorious-selector-pack';
import OpenSeaDragon from 'openseadragon';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnnotationContext } from '../../context/AnnotationContext';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';
import { SequencesContext } from '../../context/SequencesContext';
import GalleryView from './GalleryView';
import './Viewer.css';
const createElementFromHTML = (htmlString: string) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes.
  return div.children[0];
};

const formatter = (annotation: any) => {
  const tagBodies = annotation.bodies.filter((body: any) => {
    return body.type === 'TextualBody' && body.purpose === 'tagging';
  });

  if (tagBodies.length == 0) return ''; // to work with ground truth annotaiton and the polygon workflow
  return tagBodies[0].value;
};

const Viewer = ({
  annotationType,
  currentAction,
  saveCoordinates,
  currentAnnotationStep,
  annotationExists,
  updateCoordinates,
  deleteCoordinates,
  handleUndoEvent,
  undoAvailable,
  useViews,
}: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, stageId, sequenceId } = useParams();

  const { isAnnotator } = useContext(AuthContext);
  const {
    getImage,
    getImages,
    getNextImage,
    getPreviousImage,
    image,
    imageIndex,
    images,
    sequence,
    goToNextImage,
    goToPrevImage,
    setMainImage,
    setSpecificImage,
  } = useContext(SequencesContext);
  const { setLoading, setAlertMessage } = useContext(CommonContext);
  const { annotation, currentAnnotationStepIndex, showPatches, setShowPatches } = useContext(AnnotationContext);

  //const { currentAction, saveCoordinates, currentAnnotationStep, annotationExists, updateCoordinates } =
  //  useContext(AnnotationContext);
  const [fpsVideoView, setFpsVideoView] = useState<number>(2);
  const [viewer, setViewer] = useState<any>(null);
  const [hideAnno, setHideAnno] = useState<boolean>(true);
  const [mouseOnAnnotation, setMouseOnAnnotation] = useState<boolean>(false);
  const [playView, setPLayView] = useState<any>(false);
  const [galleryView, setGalleryView] = useState<any>(false);
  const [anno, setAnno] = useState<any>(null);
  const [timeoutIds, setTimeoudIds] = useState<any>([]);
  const [clickedAnnotation, setClickedAnnotation] = useState<any>(null);
  const [hiddenAnnotations, setHiddenAnnotations] = useState<any>([]);
  const [viewerReady, setViewerReady] = useState<boolean>(false);
  const [patchOpacity, setPatchOpacity] = useState<number>(1);
  const [oldPatchId, setOldPatchId] = useState<number>();

  const actionRef = useRef<any>();

  useEffect(() => {
    // /getImages(Number(sequenceId), setAlertMessage);
    if (!anno) return;
    anno.setAnnotations([]);
  }, [sequenceId]);

  useEffect(() => {
    if (!useViews) {
      setGalleryView(false);
      setPLayView(false);
      if (timeoutIds.length > 0) {
        for (const timeoutId of timeoutIds) {
          clearTimeout(timeoutId);
        }
        setTimeoudIds([]);
      }
      //InitOpenseadragon();
      setMainImage();
    }
  }, [useViews]);

  useEffect(() => {
    if (playView) {
      goToNextImage();
    } else {
      for (const timeoutId of timeoutIds) {
        clearTimeout(timeoutId);
      }
      setTimeoudIds([]);
    }
  }, [playView]);

  useEffect(() => {
    if (!currentAnnotationStep || !anno) return;
    const tempAnnotations = [];
    if (currentAnnotationStep.mainImage) {
      goToMainImage();
      if (annotationType === 'ground_truth_annotation') {
        for (const template of currentAnnotationStep.template) {
          for (const annotationId in template.annotoriousFormats) {
            const annotoriousFormat = template.annotoriousFormats[annotationId];
            if (!hiddenAnnotations.includes(annotoriousFormat.id)) tempAnnotations.push(annotoriousFormat);
          }
        }
      } else {
        for (const label of currentAnnotationStep.template) {
          if (label.annotoriousFormat) tempAnnotations.push(label.annotoriousFormat);
        }
      }
    }
    anno.setAnnotations(tempAnnotations);
    //setClickedAnnotation(null);
  }, [currentAnnotationStep]);

  useEffect(() => {
    if (!anno) return;
    if (annotationExists) anno.disableSelect = true;
    else anno.disableSelect = false;
  }, [annotationExists, anno]);

  useEffect(() => {
    if (!isAnnotator()) return;
    if (!currentAnnotationStep || !anno) return;
    const tempAnnotations = [];
    const newHiddenAnnotations = [...hiddenAnnotations];
    for (const template of currentAnnotationStep.template) {
      for (const annotationId in template.annotoriousFormats) {
        const annotoriousFormat = template.annotoriousFormats[annotationId];
        if (!newHiddenAnnotations.includes(annotoriousFormat.id)) tempAnnotations.push(annotoriousFormat);
      }
    }
    anno.setAnnotations(tempAnnotations);
  }, [hiddenAnnotations]);

  useEffect(() => {
    if (!viewer || !anno) return;
    anno.setDrawingEnabled(false);
    actionRef.current = currentAction;

    if (!currentAction) return;
    if (currentAction.type === 'annotation') {
      anno.setDrawingEnabled(true);
      anno.setDrawingTool(currentAction.tool);
      //anno.setDrawingTool('point');
    }
  }, [currentAction]);

  const InitOpenseadragon = () => {
    viewer && viewer.destroy();

    const _viewer: any = OpenSeaDragon({
      id: 'openSeaDragon',
      prefixUrl: 'http://openseadragon.github.io/openseadragon/images/',
      showNavigationControl: true,
      zoomInButton: 'zoom-in',
      zoomOutButton: 'zoom-out',
      homeButton: 'home',
      fullPageButton: 'full-page',
      maxZoomLevel: 10,
      //sequenceMode: true,
      //tileSources: [
      //  {
      //    type: 'image',
      //    url: image.s3_info.presigned_url,
      //  },
      //],
      // create an overlay of a square based on the coordinates of the center and the size of the square
      gestureSettingsMouse: {
        clickToZoom: true,
      },
    });

    _viewer.innerTracker.keyHandler = null;
    _viewer.innerTracker.keyPressHandler = null;

    _viewer.addHandler('open', () => {
      if (!playView) {
        setLoading(false);
      }
      setViewerReady(true);
    });

    _viewer.addHandler('close', () => {
      setViewerReady(false);
      setOldPatchId(undefined);
    });

    initAnnotorious(_viewer);

    setViewer(_viewer);
  };
  const initAnnotorious = (viewer: any) => {
    anno && anno.destroy();

    const config = {
      disableEditor: true,
      formatter: formatter,
    };
    const annotate = Annotorious(viewer, config);
    SelectorPack(annotate);
    annotate.setDrawingEnabled(false);

    annotate.setAnnotations([]);

    annotate.on('createSelection', async (selection: any) => {
      selection.body = [
        {
          type: 'TextualBody',
          purpose: 'tagging',
          value: actionRef.current.id,
          color: actionRef.current.color,
        },
      ];
      annotate.updateSelected(selection, true);
    });

    annotate.on('createAnnotation', (annotation: any) => {
      if (annotationType === 'ground_truth_annotation') {
        const svgPolygon = createElementFromHTML(annotation.target.selector.value);
        let pointCoordinates: any = svgPolygon.getElementsByTagName('polygon')[0].getAttribute('points');
        pointCoordinates = pointCoordinates.split(' ');
        pointCoordinates = pointCoordinates.map((point: string) => {
          return {
            x: parseInt(point.split(',')[0]),
            y: parseInt(point.split(',')[1]),
          };
        });

        saveCoordinates(pointCoordinates, annotation);
      } else {
        const annotoriousCoordinatesValue = annotation.target.selector.value.split(':');
        const bBoxString = annotoriousCoordinatesValue[1];
        const pointCoordinates = bBoxString.split(',').map(Number).slice(0, 2);
        saveCoordinates(pointCoordinates, annotation);
      }
    });
    const handleUpdatePolygon = (annotation: any) => {
      const svgPolygon = createElementFromHTML(annotation.target.selector.value);
      let pointCoordinates: any = svgPolygon.getElementsByTagName('polygon')[0].getAttribute('points');
      pointCoordinates = pointCoordinates.split(' ');
      pointCoordinates = pointCoordinates.map((point: string) => {
        return {
          x: parseInt(point.split(',')[0]),
          y: parseInt(point.split(',')[1]),
        };
      });
      updateCoordinates(annotation.id, pointCoordinates, annotation);
    };
    annotate.on('updateAnnotation', (annotation: any, previous: any) => {
      if (annotationType === 'user_annotation') {
        const annotoriousCoordinatesValue = annotation.target.selector.value.split(':');
        const bBoxString = annotoriousCoordinatesValue[1];
        const pointCoordinates = bBoxString.split(',').map(Number).slice(0, 2);
        updateCoordinates(pointCoordinates, annotation);
      } else {
        //annotate.cancelSelected();
        //setClickedAnnotation(null);
        handleUpdatePolygon(annotation);
      }
    });

    if (isAnnotator()) {
      annotate.on('selectAnnotation', (annotation: any) => {
        setClickedAnnotation(annotation);
      });

      annotate.on('cancelSelected', function (annotation: any) {
        if (clickedAnnotation) handleUpdatePolygon(clickedAnnotation);
        setClickedAnnotation(null);
      });
    }
    setAnno(annotate);
  };

  useEffect(() => {
    if (galleryView) {
      viewer.close();
    } else {
      if (!(viewer == null)) {
        if (!viewer.isOpen()) {
          InitOpenseadragon();
          //viewer.open();
          //changeImage(image);
          //viewer?.open({
          //  type: 'image',
          //  url: image.s3_info.presigned_url,
          //});
          //viewer?.setVisible();
        }
      }
    }
  }, [galleryView]);

  useEffect(() => {
    if (viewer || anno) return;
    InitOpenseadragon();
    return () => {
      viewer && viewer.destroy();
      anno && anno.destroy();
    };
  }, []);

  useEffect(() => {
    if (!viewer || !image) return;
    if (playView) {
      setTimeoudIds([...timeoutIds, setTimeout(goToNextImage, 1000 / fpsVideoView)]);
    }

    changeImage(image);
  }, [viewer, image]);

  useEffect(() => {
    if (!viewer || !viewer.isOpen() || !annotation || !currentAnnotationStep || currentAnnotationStep.completed) return;
    // Assuming the image you want is the first (and only) one in the viewer
    const tiledImage = viewer.world.getItemAt(0);

    const steps = annotation.annotation_tree.steps;

    //Remove overlays, to redraw
    viewer.clearOverlays();
    for (const step of steps) {
      const patch = step.patch;
      const isCurrentPatch = currentAnnotationStep.patch.id === patch.id;

      const viewportCoords = tiledImage.imageToViewportCoordinates(patch.x, patch.y);

      // Convert pixel dimensions of the overlay to viewport coordinates
      const overlayWidth = tiledImage
        .imageToViewportCoordinates(128, 0)
        .minus(tiledImage.imageToViewportCoordinates(0, 0)).x;
      const overlayHeight = tiledImage
        .imageToViewportCoordinates(0, 128)
        .minus(tiledImage.imageToViewportCoordinates(0, 0)).y;

      //Pan and zoom to point
      if (isCurrentPatch && patch.id !== oldPatchId) {
        viewer.viewport.panTo(viewportCoords, false).zoomTo(2, false);

        setOldPatchId(patch.id);
      }

      //If step has been completed, do not draw it
      if ((!step.completed && showPatches) || isCurrentPatch) {
        viewer.addOverlay({
          id: patch.id.toString(),
          x: viewportCoords.x - overlayWidth / 2,
          y: viewportCoords.y - overlayHeight / 2,
          width: overlayWidth,
          height: overlayHeight,
          className: isCurrentPatch ? 'overlay-patch-selected' : 'overlay-patch',
        });
      }
    }
  }, [viewerReady, currentAnnotationStep, showPatches]);

  useEffect(() => {
    if (!viewer || !viewer.isOpen() || !annotation) return;

    const steps = annotation.annotation_tree.steps;

    for (const step of steps) {
      const patch = step.patch;

      const overlay = viewer.getOverlayById(patch.id.toString());

      if (overlay) overlay.style.opacity = patchOpacity;
    }
  }, [patchOpacity, viewerReady, currentAnnotationStep, showPatches]);

  const changeImage = async (file: any) => {
    if (!playView) {
      setLoading(true);
    }
    //const img = new Image();
    //img.src = file.s3_info.presigned_url;
    //await img.decode();
    //const ts = new TileSource({
    //  url: file.s3_info.presigned_url,
    //});
    //ts.downloadTileStart();
    viewer?.open({
      type: 'image',
      url: file.s3_info.presigned_url,
      //url: img,
    });
  };

  const goToMainImage = () => {
    //const mainImageId = sequence.main_image_id;
    //navigate(`/projects/${id}/stages/${stageId}/sequences/${sequenceId}/images/${mainImageId}`);
    setGalleryView(false);
    setPLayView(false);
    setMainImage();
  };

  const nextFile = () => {
    if (currentAnnotationStep.mainImage) return;
    goToNextImage();
    //const nextImage = getNextImage();
    //navigate(`/projects/${id}/stages/${stageId}/sequences/${sequenceId}/images/${nextImage}`);
  };

  const prevFile = () => {
    if (currentAnnotationStep.mainImage) return;
    goToPrevImage();
    //const previousImage = getPreviousImage();
    //navigate(`/projects/${id}/stages/${stageId}/sequences/${sequenceId}/images/${previousImage}`);
  };

  const onDialogKeyPress = (e: { key: string }) => {
    if (e.key === 'ArrowRight') {
      nextFile();
    }
    if (e.key === 'ArrowLeft') {
      prevFile();
    }
  };

  const handleDeleteClick = () => {
    if (!clickedAnnotation) return;
    //anno.selectAnnotation(clickedAnnotation.id);
    setClickedAnnotation(null);
    deleteCoordinates(clickedAnnotation.id);
  };

  const handleHideClick = () => {
    if (!clickedAnnotation) {
      if (hideAnno) {
        anno.setVisible(false);
        setHideAnno(false);
      } else {
        anno.setVisible(true);
        setHideAnno(true);
      }
    } else {
      const tempAnnotations = [];
      const newHiddenAnnotations = [...hiddenAnnotations];
      for (const template of currentAnnotationStep.template) {
        for (const annotationId in template.annotoriousFormats) {
          const annotoriousFormat = template.annotoriousFormats[annotationId];
          if (annotoriousFormat.id === clickedAnnotation.id) {
            newHiddenAnnotations.push(annotoriousFormat.id);
          } else if (newHiddenAnnotations.includes(annotoriousFormat.id)) {
            continue;
          } else {
            tempAnnotations.push(annotoriousFormat);
          }
        }
      }
      setClickedAnnotation(null);
      setHiddenAnnotations(newHiddenAnnotations);
    }
  };

  const handleUnHideClick = () => {
    setHiddenAnnotations([]);
    setClickedAnnotation(null);
    setHideAnno(true);
    anno.setVisible(true);
  };

  const handleUndoClick = () => {
    if (undoAvailable) {
      handleUndoEvent();
    }
  };

  const handlePlayClick = () => {
    setPLayView(!playView);
  };

  const handleGalleryClick = () => {
    setGalleryView(!galleryView);
  };

  const handleImageClickGalleryView = (image: any) => {
    setSpecificImage(image);
    setGalleryView(false);
  };

  return (
    <Box
      onKeyDown={onDialogKeyPress}
      onMouseLeave={() => {
        anno.saveSelected();
        anno.cancelSelected();
        if (clickedAnnotation) {
          setClickedAnnotation(null);
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <IconButton aria-label="zoom-in" id="zoom-in" size="small">
            <ZoomInOutlinedIcon color="primary" />
          </IconButton>
          <IconButton aria-label="zoom-out" id="zoom-out" size="small">
            <ZoomOutOutlinedIcon color="primary" />
          </IconButton>
          <IconButton aria-label="home" id="home" size="small">
            <HomeOutlinedIcon color="primary" />
          </IconButton>
          <IconButton aria-label="full-page" id="full-page" size="small">
            <FullscreenOutlinedIcon color="primary" />
          </IconButton>
          {isAnnotator() ? (
            <IconButton
              onClick={() => {
                handleHideClick();
              }}
              aria-label="hide-annotation"
              id="hide-annotation"
              size="small"
              disabled={!currentAnnotationStep?.mainImage}
              color={clickedAnnotation ? 'secondary' : 'primary'}
            >
              <HideImageIcon />
            </IconButton>
          ) : null}
          {isAnnotator() ? (
            <IconButton
              onClick={() => {
                handleUnHideClick();
              }}
              aria-label="unhide-annotation"
              id="unhide-annotation"
              size="small"
              disabled={!(hiddenAnnotations.length > 0)}
              color={'secondary'}
            >
              <ImageIcon />
            </IconButton>
          ) : null}
          {isAnnotator() ? (
            <IconButton
              onClick={() => {
                handleDeleteClick();
              }}
              aria-label="delete-annotation"
              id="delete-annotation"
              size="small"
              disabled={!(clickedAnnotation !== null)}
              color={'secondary'}
            >
              <DeleteIcon />
            </IconButton>
          ) : null}

          {isAnnotator() ? (
            <IconButton
              onClick={() => {
                handleUndoClick();
              }}
              aria-label="undo-annotation"
              id="undo-annotation"
              size="small"
              disabled={!undoAvailable}
              color={'secondary'}
            >
              <UndoIcon />
            </IconButton>
          ) : null}

          {image && !galleryView ? <Typography variant="subtitle2">{image.filename}</Typography> : null}
        </Box>

        <Box pt={1} mr={1} sx={{ width: 100, zIndex: 9999 }}>
          <Slider
            valueLabelFormat={(val: any) => {
              return `Patch opacity: ${(val * 100).toFixed(0)}%`;
            }}
            aria-label="patch-opacity"
            value={patchOpacity}
            valueLabelDisplay="auto"
            step={0.01}
            marks
            min={0}
            max={1}
            onChange={(event: any) => {
              setPatchOpacity(event.target.value);
            }}
          />
        </Box>

        <IconButton
          onClick={() => {
            setShowPatches(!showPatches);
          }}
          aria-label="toggle-on-off-patches"
          id="toggle-on-off-patches"
          size="medium"
          title="Toggle patches"
          color={showPatches ? 'primary' : 'default'}
        >
          {showPatches ? <ToggleOnIcon /> : <ToggleOffIcon />}
        </IconButton>
      </Box>
      <Paper
        sx={{
          height: '85vh',
          // boxShadow: '-0px -0px 2px 4px rgba(255,144,0,0.22)',
          // border: '1px solid',
          zIndex: 4,
        }}
        elevation={3}
      >
        {galleryView ? <GalleryView handleImageClickGalleryView={handleImageClickGalleryView} /> : null}
        <Box
          id="openSeaDragon"
          sx={
            currentAction
              ? {
                  height: '81vh',
                  border: 'none',
                  cursor: 'crosshair',
                }
              : {
                  height: '81vh',
                  border: 'none',
                  cursor: 'grab',
                }
          }
          style={galleryView ? { display: 'none' } : {}}
        />
      </Paper>
    </Box>
  );
};

export default Viewer;
